<template>
<div ref="chatPanelRef" :class="`flex ${visible ? `w-auto` : 'w-0 hidden'} h-full transition-[width] shadow-2xl`" :style="isPC && `width: ${width}px`">
  <t-popup placement="left" :visible="dragHoverTipVisible" :overlay-inner-style="{ backgroundColor: '#232A35', color: 'white' }">
    <div style="height: calc(100% - 57px);" class="invisible lg:visible absolute w-1 z-50 hover:bg-[#f3f3f3] cursor-ew-resize" draggable="false" @mousedown="handleStartDrag" @mouseenter="() => { if (!isDragging) dragHoverTipVisible = true; }" @mouseleave="() => { dragHoverTipVisible = false; }"></div>
    <template #content>
      <span style="writing-mode: vertical-lr; text-orientation: upright; padding-top: 6px;">拖 动 调 节 窗 口</span>
    </template>
  </t-popup>
  <div class="flex flex-col w-full">
    <div :class="`flex flex-row border-b border-b-gy2 ${visible ? 'py-3 pl-5 pr-3' : 'p-0'}`">
      <div :class="`flex-1 flex flex-row items-center gap-3 ${visible ? 'w-auto' : 'w-0'}`">
        <div :class="`bg-icon-s bg-contain h-[50px] ${visible ? 'w-[76px]' : 'w-0'}`"></div>
        <div v-show="visible" :class="`h-[50px] flex-1 flex flex-col items-start justify-end ${visible ? 'w-auto' : 'w-0'}`">
          <div :class="`${visible ? 'w-auto' : 'w-0'}`">{{ currentConversationName || '未知客服号' }}</div>
          <div :class="`text-gy-text text-xs ${visible ? 'w-auto' : 'w-0'}`">有IT问题找我就对了</div>
        </div>
      </div>
      <div v-if="visible && !isMobile">
        <t-button shape="circle" theme="default" size="small" @click="handleClose">
          <t-icon size="16px" style="color: white;" name="close"/>
        </t-button>
      </div>
    </div>
    <div class="flex-1 min-h-0">
      <TUIKit class="h-full" :SDKAppID="user.sdkAppid"
        :userID="user.userName" :userSig="user.userSig" :conversationID="`C2C${user.helperId}`">
        <TUIChat class="h-full" />
      </TUIKit>
    </div>
  </div>
</div>
</template>
<script lang="ts" setup>
import { useChatStore } from '@/stores/chat';
import { TUIKit, TUIChat } from '../TUIKit';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { watch, ref, computed } from 'vue';
import TencentCloudChat from '@tencentcloud/chat';
import { RESIZE_PANEL_WIDTH_KEY } from '@/utils/vars';
import { isPC } from '@/TUIKit/utils/env';
import { onTUIChatLogin } from '@/utils/TuiUtils';
import { sendMessages } from '@/TUIKit/components/TUIChat/utils/sendMessage';
import { onBeforeMount } from 'vue';
import { feedbackFromBk } from '@/network/requests';

/** ------ 登陆逻辑 ------ */
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const { chatInstance, isShowingChatPanel, shouldShowRedPoint } = storeToRefs(useChatStore())

const initActionListeners = async () => {
  // 太湖同步下线
  chatInstance.value.on(TencentCloudChat.EVENT.KICKED_OUT, () => {
    userStore.logoutTai();
  })
}

watch(() => chatInstance.value, () => {
  initActionListeners();
})
/** ****** 登陆逻辑结束 ****** */

/** ------ 打开/关闭逻辑 ------ */
const visible = ref(false);
const emit = defineEmits(['chatPanelVisibilityChange']);
const show = () => {
  shouldShowRedPoint.value = false;
  visible.value = true;
  isShowingChatPanel.value = true;
  emit('chatPanelVisibilityChange', true);
};
const handleClose = () => {
  visible.value = false;
  isShowingChatPanel.value = false;
  emit('chatPanelVisibilityChange', false);
}
defineExpose({
  show,
})
const isMobile = computed(() => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
})
/** ****** 打开/关闭逻辑结束 ****** */

/** ------ 标题逻辑 ------ */
const { currentConversation } = storeToRefs(useChatStore());
const currentConversationName = computed(() => currentConversation.value?.getShowName() || '');

/** ****** 标题逻辑结束 ****** */

/** ****** 拖动逻辑 ****** */
const dragHoverTipVisible = ref(false);
const isDragging = ref(false);
const chatPanelRef = ref<HTMLElement>();
const initialWidth = Number(localStorage.getItem(RESIZE_PANEL_WIDTH_KEY)) || 480;
const width = ref(initialWidth);
const handleMouseMove = (e: MouseEvent) => {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  width.value = Math.min(Math.max(vw - e.clientX, 500), 680);
}

const handleStartDrag = () => {
  dragHoverTipVisible.value = false;
  isDragging.value = true;
  chatPanelRef.value.classList.remove('transition-[width]');
  window.addEventListener('mousemove', handleMouseMove);
  window.addEventListener('mouseup', handleStopDrag);
}
const handleStopDrag = () => {
  isDragging.value = false;
  chatPanelRef.value.classList.add('transition-[width]');
  localStorage.setItem(RESIZE_PANEL_WIDTH_KEY, `${width.value}`);
  window.removeEventListener('mousemove', handleMouseMove);
  window.removeEventListener('mouseup', handleStopDrag);
}
/** ****** 拖动逻辑结束 ****** */

onBeforeMount(async () => {
  /** ****** 一键求助逻辑 ****** */
  try {
    const url = new URL(window.location.href);
    const landunState = url.searchParams.get('landun_state') ? JSON.parse(url.searchParams.get('landun_state')) : undefined;
    const state = url.searchParams.get('state') ? JSON.parse(url.searchParams.get('state')) : undefined;
    if (!landunState && !state) return;
    const cloud_id = landunState ? landunState.cloud_id : state.cloud_id;
    const cloud_question = landunState ? landunState.cloud_question : state.cloud_question;
    const prod_id = Number(state && state.prod_id ? state.prod_id : url.searchParams.get('prod_id'));
    if (!cloud_id || !cloud_question) return;
    // 消费后删除
    url.searchParams.delete("landun_state");
    url.searchParams.delete("state");
    window.history.replaceState({}, null, `${url.pathname}?${url.searchParams.toString()}`);
    // 注册 hook
    feedbackFromBk({ bk_id: `${cloud_id}`, bk_question: cloud_question, prod_id });
    await onTUIChatLogin(async () => {
      await sendMessages([{
        type: 'text',
        payload: {
          text: cloud_question,
        }
      }], currentConversation.value)
      // 打开聊天框
      show();
    });
  } catch (error) {
    console.log('发送一键求助问题失败,请尝试在聊天框中输入问题');
    // MessagePlugin.error('发送一键求助问题失败,请尝试在聊天框中输入问题');
  }
  /** ****** 一键求助逻辑结束 *** */
});
</script>
