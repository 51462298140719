import { PartialMessage } from "@bufbuild/protobuf"
import axiosInstance from "./axios"
import api from "./api"
import { GetTicketDetailReq, GetTicketDetailRsp, DoActionReq, DoActionRsp, QueryImActionInfoReq, QueryImActionInfoRsp } from "@/gen/common_ticket_without_validate_pb"
import { GetLinkageRuleConfigReq, GetLinkageRuleConfigRsp } from "@/gen/product_without_validate_pb"
import { UploadReq,UploadRsp } from "@/gen/upload-service_without_validate_pb"
import { GetWebGlobalSessionIdReq,GetWebGlobalSessionIdRsp, RefreshGlobalSessionReq,RefreshGlobalSessionRsp } from "@/gen/offshore_pb"
import {
  KnowledgeSearchReq, KnowledgeSearchRsp, GetCommonQuestionReq, GetCommonQuestionRsp,
  KnowledgeSearchHistoryReq, KnowledgeSearchHistoryRsp, DelKnowledgeSearchReq,DelKnowledgeSearchRsp,
  FeedbackFromBkReq,
  FeedbackFromBkRsp
} from '@/gen/proxy_pb'

export const getLinkageRules = (
  data: PartialMessage<GetLinkageRuleConfigReq>
): Promise<PartialMessage<GetLinkageRuleConfigRsp>> => {
  return axiosInstance.post(api.getLinkageRules, data)
}

export const getTicketDetail = (
  data: PartialMessage<GetTicketDetailReq>
): Promise<PartialMessage<GetTicketDetailRsp>> => {
  return axiosInstance.post(api.getTicketDetail, data)
}

export const doAction = (
  data: PartialMessage<DoActionReq>
): Promise<DoActionRsp> => {
  return axiosInstance.post(api.doAction, data)
}

export const tcccCallback = (
  data: any
): Promise<void> => {
  return axiosInstance.post(api.tcccCallback, data)
}

export const tcccSessionCallback = (
  data: any
): Promise<void> => {
  return axiosInstance.post(api.tcccSessionCallback, data)
}

export const uploadImActionInfo = (
  data: PartialMessage<DoActionReq>
): Promise<PartialMessage<DoActionRsp>> => {
  return axiosInstance.post(api.uploadImActionInfo, data)
}

export const queryImActionInfo = (
  data: PartialMessage<QueryImActionInfoReq>
): Promise<PartialMessage<QueryImActionInfoRsp>> => {
  return axiosInstance.post(api.queryImActionInfo, data)
}

export const doRequest = (data)=>{
  return axiosInstance.post(api.proxy,data)
}

export const getWebGlobalSessionId = (
  params: PartialMessage<GetWebGlobalSessionIdReq>
): Promise<GetWebGlobalSessionIdRsp> => {
  return axiosInstance.get(api.getWebGlobalSessionId, {params})
}

export const refreshGlobalSession = (
  params: PartialMessage<RefreshGlobalSessionReq>
): Promise<RefreshGlobalSessionRsp> => {
  return axiosInstance.get(api.refreshGlobalSession, {params})
}

export const uploadFlow = (
  data: PartialMessage<UploadReq>
): Promise<UploadRsp> => {
  return axiosInstance.post(api.uploadFlow, data)
}

export const knowledgeSearch = (
  params: PartialMessage<KnowledgeSearchReq>
): Promise<KnowledgeSearchRsp> => {
  return axiosInstance.get(api.knowledgeSearch, {params})
}

export const getCommonQuestion = (
  params: PartialMessage<GetCommonQuestionReq>
): Promise<GetCommonQuestionRsp> => {
  return axiosInstance.get(api.getCommonQuestion, {params})
}

export const knowledgeSearchHistory = (
  params: PartialMessage<KnowledgeSearchHistoryReq>
): Promise<KnowledgeSearchHistoryRsp> => {
  return axiosInstance.get(api.knowledgeSearchHistory, {params})
}

export const delKnowledgeSearch = (
  params: PartialMessage<DelKnowledgeSearchReq>
): Promise<DelKnowledgeSearchRsp> => {
  return axiosInstance.get(api.delKnowledgeSearch, {params})
}

export const knowledgeChat = (
  data: { user: string, query: string, projId: number }
): Promise<{ code: number, msg: string, data: { answerType: string, answer: string, msgId: string }[] }> => {
  return axiosInstance.post(api.knowledgeChat, data)
}

export const DoRequest = (data,method)=>{
  return axiosInstance.post(api.DoRequest,
  {
      "service": "trpc.servicedesk.offshore.Offshore",
      "method": method||"GET",
      "interface_name": data
  })
}

export const qaFeedback = (
  data: { msg_id: string, helpful: 0 | 1 | 2, answer_feedback?: string[] }
): Promise<{ code: number, msg: string }> => {
  return axiosInstance.post(api.qaFeedback, data);
}

export const feedbackFromBk = (
  params: { bk_id: string, bk_question: string, prod_id: number }
): Promise<{ code: number, msg: string }> => {
  return axiosInstance.post(api.feedbackFromBk, params)
}
