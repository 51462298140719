import { marked } from "marked";

marked.use({
  gfm: true,
  breaks: true,
  renderer: {
    link(link) {
      const { href, raw, text, title, tokens, type } = link;
      return `<a href="${href}" style="color: #0052D9;" target="_blank" title="${title}">${text}</a>`;
    }
  }
})

export default marked;
